import React, { memo, useEffect, useState } from 'react';
import './YourSelfContainer.scss';
import {
  Checkbox,
  Col,
  Form as AntForm,
  FormInstance,
  Input,
  Popover,
  Row,
  Select,
  Radio,
  RadioChangeEvent,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  setName,
  setEmail,
  setPhone,
  setChannels,
  setMessageContent,
  setWhatsAppNumber,
  setIsWhatsAppNumber,
  setDialCode,
  setCountryCode,
  setCity,
  setAddress,
  setUniversityPreference,
} from './SayHelloPopupSlice';
import { getSayHelloPopupSelector } from './SayHelloPopupSlice';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCountryCode } from '../PeopleTab/useCountryCode';
import { countryData } from '../../../../constants/countryData';
import { fetchIndianCity, fetchIndianState } from './CustomStateSlice';

import {
  composeValidators,
  minTextLimit,
  required,
  validateEmail,
  validatePhone,
  validateDifferentNumbers,
} from '../../../../utils/Validations/Validations';
import { UniversityResourceState } from '../../../../MainScreen/MainScreenSlice';
import { getUniversityResourceSelector } from '../../../../MainScreen/MainScreenSlice';
import { getIpDetailsSelector } from '../../../../api/IpDataSlice';
import { domainValidation } from '../../../common/utils';
import GoogleAutocompleteInput from './GoogleAutocompleteInput';
import { universityPreferrenceDto } from './SayHelloPopupSlice';
import { isMobile } from 'react-device-detect';

export type channelTypeProps = 'EMAIL' | 'SMS' | 'WHATSAPP';

export type RegisterProspectProps = {
  message: string;
  email: string;
  phone: string;
  whatsappNumber: string | undefined;
  name: string;
  channels: channelTypeProps[];
  isWhatsAppNumber: boolean;
  courseId: string[];
  dialCode: string;
  city: string;
  address: string;
  universityPreferrenceDto: universityPreferrenceDto | null;
};

interface YourSelfContainerProps {
  onNext: (regProps: RegisterProspectProps) => void;
  messageContent: string;
  initValue: RegisterProspectProps;
  agreedToTerms: boolean;
  setAgreedToTerms: (checked: boolean) => void;
  universityResourceState: UniversityResourceState | null;
  countryCode?: any;
  setIsRecaptchaValidate?: any;
}

const YourSelfContainer = ({
  onNext,
  messageContent,
  initValue,
  agreedToTerms,
  setAgreedToTerms,
  universityResourceState,
  countryCode,
  setIsRecaptchaValidate,
}: YourSelfContainerProps) => {
  const { payload } = useAppSelector(getIpDetailsSelector);
  const formRef = React.createRef<FormInstance>();
  const [reqRequest, setReqRequest] = useState<RegisterProspectProps>(initValue);
  const [differentWhatsApNumber, setDifferentWhatsApNumber] = useState(false);

  const initialCountryCode = useCountryCode();
  const [isCountryCode, setIsCountryCode] = useState<string>('');
  // const [isRecaptchaValidate, setIsRecaptchaValidate] = useState<boolean>(false);

  const [indianCampus, setIndianCampus] = useState<'withinIndia' | 'outsideIndia'>('withinIndia');
  const [leadCountry, setLeadCountry] = useState<string>('');

  const dispatch = useAppDispatch();
  const prospectUserInfo = useAppSelector((state) => state.chatPopups);
  const uniPayload = useAppSelector(getUniversityResourceSelector);
  const indianState = useAppSelector((state) => state.indianState);
  const indianUniversityEnabled = uniPayload?.payload?.univeristyConfig?.customCityFilterEnabled;
  const isCampusLocationEnabled = uniPayload?.payload?.univeristyConfig?.activeMultiLocations;

  const { Option } = Select;

  useEffect(() => {
    dispatch(fetchIndianState());
  }, [dispatch]);

  useEffect(() => {
    if (initialCountryCode) {
      setIsCountryCode(initialCountryCode);
    }
  }, [initialCountryCode]);

  useEffect(() => {
    dispatch(setCountryCode(isCountryCode));
  }, [dispatch, isCountryCode]);

  useEffect(() => {
    setReqRequest({
      message: prospectUserInfo.messageContent,
      email: prospectUserInfo.email,
      phone: prospectUserInfo.phone,
      whatsappNumber: prospectUserInfo.whatsappNumber,
      name: prospectUserInfo.name,
      channels: prospectUserInfo.channels,
      isWhatsAppNumber: prospectUserInfo.isWhatsAppNumber,
      courseId: prospectUserInfo.courseId,
      dialCode: prospectUserInfo.dialCode ?? '',
      city: prospectUserInfo.city,
      address: prospectUserInfo.address,
      universityPreferrenceDto: prospectUserInfo.universityPreferrenceDto,
    });
  }, [prospectUserInfo]);

  const isWhatsAppNumber = useAppSelector((state) => state.chatPopups.isWhatsAppNumber);

  const handleWhatsAppChange = (isChecked: boolean) => {
    dispatch(setIsWhatsAppNumber(!isChecked));
    if (!isChecked) {
      dispatch(setWhatsAppNumber(''));
    }
  };

  useEffect(() => {
    setDifferentWhatsApNumber(!isWhatsAppNumber);
  }, [isWhatsAppNumber]);

  // useEffect(() => {
  //   setReqRequest({ ...reqRequest, message: messageContent });
  // }, [messageContent]);

  useEffect(() => {}, [reqRequest]);

  const { t } = useTranslation();

  const onFinish = (values: any) => {};

  // const onChange = (checkedValues: CheckboxValueType[]) => {
  //   setReqRequest({
  //     ...reqRequest,
  //     channels: checkedValues as [channelTypeProps],
  //   });
  // };

  // const plainOptions = ['SMS', 'WHATSAPP'];
  // const CheckboxGroup = Checkbox.Group;

  const handleFieldChange = (fieldName: any, value: any) => {
    setReqRequest((prev) => ({ ...prev, [fieldName]: value }));
    switch (fieldName) {
      case 'name':
        dispatch(setName(value));
        break;
      case 'email':
        dispatch(setEmail(value));
        break;
      case 'phone':
        dispatch(setPhone(value));
        break;
      case 'channels':
        dispatch(setChannels(value));
        break;
      case 'whatsApp':
        dispatch(setWhatsAppNumber(value));
        break;
      case 'messageContent':
        dispatch(setMessageContent(value));
        break;
      case 'dialCode':
        dispatch(setDialCode(value));
        break;
      case 'city':
        dispatch(setCity(value));
        break;
      case 'address':
        dispatch(setAddress(value));
        break;
      case 'withinIndia':
        dispatch(
          setUniversityPreference({
            withInIndia: value,
          })
        );
        break;
      case 'outsideIndia':
        dispatch(
          setUniversityPreference({
            outsideIndia: value,
          })
        );
        break;
      case 'applyingFromCountry':
        dispatch(
          setUniversityPreference({
            applyingFromCountry: value,
          })
        );
        break;
      case 'preferredCity':
        dispatch(
          setUniversityPreference({
            preferredCity: value,
          })
        );
        break;
      case 'preferredState':
        dispatch(
          setUniversityPreference({
            preferredState: value,
          })
        );
        break;
      case 'preferredUniversityLocation':
        dispatch(
          setUniversityPreference({
            preferredUniversityLocation: value,
          })
        );
        break;
    }
  };

  const content = (
    <div className="chatRulesPopoverLayout">
      <div className="dosTextHeader">{t('dashboard.SayHelloPopup.dosTextHeader')}</div>
      <div className="dosTextDescrip">{t('dashboard.SayHelloPopup.dosTextDescrip')} </div>
      <div className="dontsTextHeader">{t('dashboard.SayHelloPopup.dontsTextHeader')}</div>
      <div className="dontsTextDescrip">{t('dashboard.SayHelloPopup.dontsTextDescrip')} </div>
    </div>
  );
  // console.log('== universityResourceState ==', universityResourceState);

  // @ts-ignore

  const formState = useAppSelector(getSayHelloPopupSelector);

  const courseId = useAppSelector((state) => state.chatPopups.courseId);

  const handleOnSubmit = () => {
    const registerProspectData: RegisterProspectProps = {
      message: formState.messageContent,
      email: formState.email,
      phone: formState.phone,
      whatsappNumber: formState.whatsappNumber,
      name: formState.name,
      channels: formState.channels,
      isWhatsAppNumber: formState.isWhatsAppNumber,
      courseId,
      dialCode: formState.dialCode,
      city: formState.city,
      address: formState.address,
      universityPreferrenceDto: indianUniversityEnabled ? formState.universityPreferrenceDto : null,
    };

    onNext(registerProspectData);
  };

  const onChangeReCaptcha = (value: any) => {
    // console.log('=== recaptchaValue ===', value);
    if (value) {
      setIsRecaptchaValidate(true);
    }
  };

  const onExpiredReCaptcha = () => {
    setIsRecaptchaValidate(false);
  };

  // const compareDomain = (domain: any) => {
  //   const restrictedDomains = [
  //     'shoolini.univiser.io',
  //     'amraksl.univiser.io',
  //     'ecusl.univiser.io',
  //     'uclsl.univiser.io',
  //     'ricsl.univiser.io',
  //     'mitwpu.univiser.io',
  //   ];
  //   return restrictedDomains.includes(domain);
  // };

  // const domainValidation = () => !compareDomain(universityResourceState?.domain);

  // console.log('==universityResourceState Return==', universityResourceState?.univeristyConfig);

  return (
    <>
      <div className="addSummeryForm">
        <Form
          initialValues={formState}
          onSubmit={handleOnSubmit}
          render={({ handleSubmit }: any) => (
            <form className="addSummeryFormIn" onSubmit={handleSubmit} id={'addSummeryForm'}>
              <Content>
                <AntForm ref={formRef} name="control-ref" onFinish={onFinish} layout="vertical">
                  <Row gutter={16}>
                    <Col className="yourName" xs={24} sm={24} md={12} lg={12}>
                      <Field
                        name="name"
                        validate={composeValidators(required, (text: string) => minTextLimit(text, 3))}
                      >
                        {({ input, meta, ...rest }) => (
                          <AntForm.Item
                            className="yourNameText"
                            name="name"
                            label={t('dashboard.YourSelfContainer.yourName')}
                            validateStatus={meta.touched && meta.error ? 'error' : ''}
                            help={meta.touched && t(meta.error)}
                            rules={[{ required: true }]}
                          >
                            <div className="yourNameInput">
                              <Input
                                className="ant-input yourNameInputIn"
                                value={reqRequest?.name}
                                placeholder={t('dashboard.YourSelfContainer.yourNameHint') || ''}
                                onChange={(e) => handleFieldChange('name', e.target.value)}
                                {...rest}
                              />
                            </div>
                          </AntForm.Item>
                        )}
                      </Field>
                    </Col>
                    <Col className="yourEmailAddress" xs={24} sm={24} md={12} lg={12}>
                      <Field
                        className="yourEmailAddressIn"
                        placeholder={t('dashboard.YourSelfContainer.yourEmailAddressHint')}
                        name="email"
                        validate={composeValidators(required, (input: string) => validateEmail(input))}
                      >
                        {({ input, meta, ...rest }) => (
                          <AntForm.Item
                            className="yourNameText"
                            name="email"
                            tooltip={t('dashboard.SayHelloPopup.infoButton')}
                            label={t('dashboard.YourSelfContainer.yourEmailAddress')}
                            validateStatus={meta.touched && meta.error ? 'error' : ''}
                            help={meta.touched && t(meta.error)}
                            rules={[{ required: true }]}
                          >
                            <div className="yourEmailAddressInput">
                              <Input
                                onChange={(e) => handleFieldChange('email', e.target.value)}
                                {...rest}
                                value={reqRequest?.email}
                              />
                            </div>
                          </AntForm.Item>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Field name="yourMobileNumber">
                      {({ input, meta, ...rest }) => (
                        <AntForm.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                        >
                          <Col className="yourMobileNumber" xs={24} sm={24} md={12} lg={12}>
                            <div>
                              <Field
                                className="yourMobileNumberIn"
                                placeholder={t('dashboard.YourSelfContainer.yourMobileNumberHint')}
                                name="phone"
                                validate={composeValidators(required, (input: string) =>
                                  validatePhone(input, prospectUserInfo.dialCode, isCountryCode)
                                )}
                              >
                                {({ input, meta, ...rest }) => (
                                  <AntForm.Item
                                    className="yourMobileNumberText"
                                    name="phone"
                                    validateStatus={meta.touched && meta.error ? 'error' : ''}
                                    help={meta.touched && t(meta.error)}
                                    label={t('dashboard.YourSelfContainer.yourMobileNumber')}
                                    rules={[{ required: true }]}
                                  >
                                    <div className="yourMobileNumberInput">
                                      <PhoneInput
                                        searchPlaceholder="Search"
                                        country={countryCode ? countryCode.toLowerCase() : ''}
                                        enableSearch={true}
                                        containerClass="yourEmailAddressIn"
                                        placeholder="Enter phone number"
                                        value={reqRequest?.phone}
                                        onChange={(value: string, country: any) => {
                                          handleFieldChange('phone', value);
                                          handleFieldChange('dialCode', country?.dialCode);
                                          setIsCountryCode(country.countryCode);
                                        }}
                                        {...rest}
                                      />
                                    </div>
                                  </AntForm.Item>
                                )}
                              </Field>
                            </div>
                            <div style={{ marginBottom: isMobile ? '6px' : undefined }}>
                              <Checkbox
                                className="Checkbox"
                                checked={isWhatsAppNumber}
                                onChange={(e) => handleWhatsAppChange(!e.target.checked)}
                              >
                                {t('dashboard.YourSelfContainer.thisIsMyWhatsApp')}
                              </Checkbox>
                            </div>
                          </Col>
                        </AntForm.Item>
                      )}
                    </Field>

                    {!isWhatsAppNumber && (
                      <Field name="yourWhatsAppNumber">
                        {({ input, meta, ...rest }) => (
                          <AntForm.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                          >
                            <Col className="yourMobileNumber" xs={24} sm={24} md={12} lg={12}>
                              <div>
                                <Field
                                  className="yourMobileNumberIn"
                                  placeholder={t('dashboard.YourSelfContainer.yourMobileNumberHint')}
                                  name="whatsappNumber"
                                  validate={composeValidators(
                                    required,
                                    (input: string) => validatePhone(input, prospectUserInfo.dialCode, isCountryCode),
                                    validateDifferentNumbers(reqRequest)
                                  )}
                                >
                                  {({ input, meta, ...rest }) => (
                                    <AntForm.Item
                                      className="yourMobileNumberText"
                                      name="whatsappNumber"
                                      validateStatus={meta.touched && meta.error ? 'error' : ''}
                                      help={meta.touched && t(meta.error)}
                                      label={t('dashboard.YourSelfContainer.whatsAppNumber')}
                                      rules={[{ required: true }]}
                                    >
                                      <div className="yourMobileNumberInput">
                                        <PhoneInput
                                          searchPlaceholder="Search"
                                          country={'lk'}
                                          enableSearch={true}
                                          containerClass="yourEmailAddressIn"
                                          placeholder="Enter phone number"
                                          value={reqRequest?.whatsappNumber}
                                          onChange={(value: string, country: any) => {
                                            handleFieldChange('whatsApp', value);
                                            setIsCountryCode(country.countryCode);
                                          }}
                                          {...rest}
                                        />
                                      </div>
                                    </AntForm.Item>
                                  )}
                                </Field>
                              </div>
                            </Col>
                          </AntForm.Item>
                        )}
                      </Field>
                    )}

                    {!indianUniversityEnabled && (
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <p style={{ marginBottom: '8px', marginTop: !isWhatsAppNumber ? '16px' : '4px' }}>
                          <span
                            style={{
                              color: '#FF4D4F',
                              fontWeight: 'bold',
                              marginRight: '4px',
                              fontSize: '10px',
                            }}
                          >
                            *
                          </span>
                          Where are you applying from? (City Name)
                        </p>
                        <GoogleAutocompleteInput
                          onPlaceSelected={(place) => {
                            if (place.name) {
                              handleFieldChange('city', place.name);
                            }
                            if (place.address) {
                              handleFieldChange('address', place.address);
                            }
                          }}
                        />
                      </Col>
                    )}
                  </Row>

                  {/* Integration of indian uni state city feature */}
                  {indianUniversityEnabled && (
                    <>
                      <Row>
                        <Col span={24}>
                          <Field name="location">
                            {({ input, meta, ...rest }) => (
                              <AntForm.Item
                                className="yourNameText"
                                style={{ marginTop: '1rem' }}
                                name="location"
                                label={t('dashboard.YourSelfContainer.location')}
                                help={meta.touched && t(meta.error)}
                                rules={[{ required: true }]}
                              >
                                <div className="yourNameInput">
                                  <Radio.Group
                                    onChange={(e: RadioChangeEvent) => {
                                      const selectedValue = e.target.value;
                                      setIndianCampus(selectedValue);

                                      handleFieldChange('withinIndia', selectedValue === 'withinIndia');
                                      handleFieldChange('outsideIndia', selectedValue === 'outsideIndia');

                                      if (selectedValue === 'withinIndia') {
                                        handleFieldChange('applyingFromCountry', '');
                                        setLeadCountry('');
                                      } else {
                                        handleFieldChange('preferredState', null);
                                        handleFieldChange('preferredCity', '');
                                      }

                                      handleFieldChange('preferredUniversityLocation', null);
                                    }}
                                    value={indianCampus}
                                    options={[
                                      { value: 'withinIndia', label: 'Within india' },
                                      { value: 'outsideIndia', label: 'Outside india' },
                                    ]}
                                  />
                                </div>
                              </AntForm.Item>
                            )}
                          </Field>
                        </Col>
                      </Row>
                      {indianCampus === 'withinIndia' && (
                        <>
                          <Row gutter={16}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                              <Field
                                name="universityPreferrenceDto.preferredState"
                                validate={composeValidators(required)}
                              >
                                {({ input, meta, ...rest }) => (
                                  <AntForm.Item
                                    className="yourNameText"
                                    label={t('dashboard.YourSelfContainer.whichStateAreYou')}
                                    validateStatus={meta.touched && meta.error ? 'error' : ''}
                                    help={meta.touched && t(meta.error)}
                                    required={true}
                                  >
                                    <div className="yourNameInput">
                                      <Select
                                        placeholder={t('dashboard.YourSelfContainer.statePlaceholder')}
                                        onChange={(value: number) => {
                                          handleFieldChange('preferredState', value);
                                          dispatch(fetchIndianCity(value));
                                        }}
                                        style={{ width: '100%' }}
                                        optionFilterProp="label"
                                        value={reqRequest?.universityPreferrenceDto?.preferredState || undefined}
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                      >
                                        {indianState.states.map((state) => {
                                          return (
                                            <Option key={state.id} value={state.id}>
                                              {state.stateName}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </AntForm.Item>
                                )}
                              </Field>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={12}>
                              <Field
                                name="universityPreferrenceDto.preferredCity"
                                validate={composeValidators(required)}
                              >
                                {({ input, meta, ...rest }) => (
                                  <AntForm.Item
                                    className="yourNameText"
                                    label={t('dashboard.YourSelfContainer.whichCityAreYou')}
                                    validateStatus={meta.touched && meta.error ? 'error' : ''}
                                    help={meta.touched && t(meta.error)}
                                    required={true}
                                  >
                                    <div className="yourNameInput">
                                      <Select
                                        placeholder={t('dashboard.YourSelfContainer.cityPlaceholder')}
                                        onChange={(value) => handleFieldChange('preferredCity', value)}
                                        value={reqRequest.universityPreferrenceDto?.preferredCity || undefined}
                                        style={{ width: '100%' }}
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                      >
                                        {indianState.cities.map((city) => {
                                          return (
                                            <Option key={city.id} value={city.cityName}>
                                              {city.cityName}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </AntForm.Item>
                                )}
                              </Field>
                            </Col>
                          </Row>
                        </>
                      )}
                      {/* Outside india */}
                      {indianCampus === 'outsideIndia' && (
                        <Row>
                          <Col span={24}>
                            <Field
                              name="universityPreferrenceDto.applyingFromCountry"
                              validate={composeValidators(required)}
                            >
                              {({ input, meta, ...rest }) => (
                                <AntForm.Item
                                  className="yourNameText"
                                  label={t('dashboard.YourSelfContainer.whichCountryAreYou')}
                                  validateStatus={meta.touched && meta.error ? 'error' : ''}
                                  help={meta.touched && t(meta.error)}
                                  required={true}
                                >
                                  <div className="yourNameInput">
                                    <Select
                                      showSearch
                                      placeholder={t('dashboard.YourSelfContainer.countryPlaceholder')}
                                      value={leadCountry || undefined}
                                      onChange={(value: string) => {
                                        setLeadCountry(value);
                                        handleFieldChange('applyingFromCountry', value);
                                      }}
                                      optionFilterProp="label"
                                      style={{ width: '100%' }}
                                      getPopupContainer={(trigger) => trigger.parentElement}
                                    >
                                      {countryData.map((country) => {
                                        return (
                                          <Option key={country.value} value={country.value} label={country.label}>
                                            {country.label}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </AntForm.Item>
                              )}
                            </Field>
                          </Col>
                        </Row>
                      )}
                      {isCampusLocationEnabled && (
                        <Row>
                          <Col span={24}>
                            <Field
                              name="universityPreferrenceDto.preferredUniversityLocation"
                              validate={composeValidators(required)}
                            >
                              {({ input, meta, ...rest }) => (
                                <AntForm.Item
                                  className="yourNameText"
                                  label={t('dashboard.YourSelfContainer.campusPreference')}
                                  validateStatus={meta.touched && meta.error ? 'error' : ''}
                                  help={meta.touched && t(meta.error)}
                                  required={true}
                                >
                                  <div className="yourNameInput">
                                    <Select
                                      placeholder={t('dashboard.YourSelfContainer.campusPlaceholder')}
                                      value={
                                        reqRequest?.universityPreferrenceDto?.preferredUniversityLocation || undefined
                                      }
                                      onChange={(value) => {
                                        handleFieldChange('preferredUniversityLocation', value);
                                      }}
                                      style={{ width: '100%' }}
                                      getPopupContainer={(trigger) => trigger.parentElement}
                                    >
                                      {uniPayload.payload?.locations.map((location) => {
                                        return <Option value={location.id}>{location.locationName}</Option>;
                                      })}
                                    </Select>
                                  </div>
                                </AntForm.Item>
                              )}
                            </Field>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </AntForm>

                {/* {!indianUniversityEnabled && (
                  <Row>
                    <Col span={12}>
                      <p style={{ marginBottom: '8px', marginTop: '16px' }}>
                        <span
                          style={{
                            color: '#FF4D4F',
                            fontWeight: 'bold',
                            marginRight: '4px',
                            fontSize: '10px',
                          }}
                        >
                          *
                        </span>
                        Where are you applying from? (City Name)
                      </p>
                      <GoogleAutocompleteInput
                        onPlaceSelected={(place) => {
                          if (place.name) {
                            handleFieldChange('city', place.name);
                          }
                          if (place.address) {
                            handleFieldChange('address', place.address);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                )} */}

                {/* <div className="notifyCheckBoxContent">
                  <div>{t('dashboard.YourSelfContainer.selectNotificationChannelText')}</div>
                  <div className="notifyCheckBox">
                    <Field name="channel">
                      {({ input, meta, ...rest }) => (
                        <AntForm.Item
                          name="channel"
                          valuePropName="checked"
                          validateStatus={meta.touched && meta.error ? 'error' : ''}
                          help={meta.touched && meta.error}
                          label=""
                        >
                          <Checkbox defaultChecked disabled>
                            EMAIL
                          </Checkbox>

                          <CheckboxGroup
                            value={reqRequest?.channels}
                            // options={plainOptions}
                            options={
                              domainValidation(universityResourceState)
                                ? plainOptions
                                : plainOptions.filter((option) => option !== 'WHATSAPP')
                            }
                            onChange={(value) => handleFieldChange('channels', value)}
                          ></CheckboxGroup>
                        </AntForm.Item>
                      )}
                    </Field>
                  </div>
                </div> */}
              </Content>
            </form>
          )}
        />
        {universityResourceState?.univeristyConfig?.recaptureEnabled ? (
          <Col lg={15} xs={24} style={{ marginTop: 10 }}>
            <ReCAPTCHA
              sitekey={`${universityResourceState?.univeristyConfig?.googleRecaptchaKey}`}
              onChange={onChangeReCaptcha}
              onExpired={onExpiredReCaptcha}
              type="image"
            />
          </Col>
        ) : (
          <></>
        )}

        <Col lg={24} xs={24} style={{ marginTop: 10 }}>
          <Checkbox className="Checkbox" checked={agreedToTerms} onChange={() => setAgreedToTerms(!agreedToTerms)}>
            <div className="CheckboxText">
              <span> {t('dashboard.SayHelloPopup.checkboxText')} </span>
              <a
                className="termsOfUseText"
                href={process.env.REACT_APP_TERMS_AND_CONDITION_URL}
                rel="noreferrer"
                style={{
                  color: `${universityResourceState?.theme?.themeColor}`,
                }}
                target="_blank"
              >
                {t('dashboard.SayHelloPopup.termsOfUseText')}
              </a>{' '}
              <a
                className="privacyPolicyText"
                href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                rel="noreferrer"
                style={{
                  color: `${universityResourceState?.theme?.themeColor}`,
                }}
                target="_blank"
              >
                {t('dashboard.SayHelloPopup.privacyPolicyText')}
              </a>{' '}
              {t('dashboard.SayHelloPopup.privacyPolicyTextAnd')}{' '}
              <Popover className="popoverLayout" placement="bottomRight" content={content} trigger="click">
                <a
                  className="chatRulesText"
                  href="Chat Rules"
                  style={{
                    color: `${universityResourceState?.theme?.themeColor}`,
                  }}
                >
                  {t('dashboard.SayHelloPopup.chatRulesText')}
                </a>
              </Popover>
            </div>
          </Checkbox>
        </Col>
      </div>
    </>
  );
};

export default memo(YourSelfContainer);
