import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getIndianCityRequest, getIndianStateRequest } from './CustomStateAPI';

interface indianCustomState {
  id: number;
  stateName: string;
}

export type indianCustomCity = {
  id: number;
  cityName: string;
};

interface indianCustomStateSlice {
  states: indianCustomState[];
  cities: indianCustomCity[];
}

const initialState: indianCustomStateSlice = {
  states: [],
  cities: [],
};

export const fetchIndianState = createAsyncThunk('get/indian/state', async () => {
  try {
    const response = await getIndianStateRequest();
    return response;
  } catch (error) {
    throw new Error(`Failed to fetch indian states ${error}`);
  }
});

export const fetchIndianCity = createAsyncThunk('get/indian/city', async (stateId: number) => {
  try {
    const response = await getIndianCityRequest(stateId);
    return response;
  } catch (error) {
    throw new Error(`Failed to fetch indian cities ${error}`);
  }
});

const indianStateSlice = createSlice({
  name: 'indianState',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIndianState.fulfilled, (state, action) => {
      state.states = action.payload.states;
    });
    builder.addCase(fetchIndianCity.fulfilled, (state, action) => {
      state.cities = action.payload.cityDtoList;
    });
  },
});

export default indianStateSlice.reducer;
