import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';
import { channelTypeProps } from './YourSelfContainer';

export interface universityPreferrenceDto {
  withInIndia: boolean;
  outsideIndia: boolean;
  applyingFromCountry: string;
  preferredCity: string;
  preferredUniversityLocation: null;
  preferredState: null;
}

interface sayHelloPopupsState {
  messageContent: string;
  hasApplied: number;
  selectedCourse: string[];
  name: string;
  email: string;
  phone: string;
  channels: channelTypeProps[];
  whatsappNumber: string;
  isWhatsAppNumber: boolean;
  courseId: string[];
  dialCode: string;
  countryCode: string;
  city: string;
  address: string;
  universityPreferrenceDto: universityPreferrenceDto;
}

const initialState: sayHelloPopupsState = {
  messageContent: '',
  hasApplied: 1,
  selectedCourse: [],
  name: '',
  email: '',
  phone: '',
  channels: ['EMAIL'],
  whatsappNumber: '',
  isWhatsAppNumber: true,
  courseId: [],
  dialCode: '',
  countryCode: '',
  city: '',
  address: '',
  universityPreferrenceDto: {
    withInIndia: true,
    outsideIndia: false,
    applyingFromCountry: '',
    preferredCity: '',
    preferredUniversityLocation: null,
    preferredState: null,
  },
};

const sayHelloPopupSlice = createSlice({
  name: 'sayHelloPopup',
  initialState,
  reducers: {
    setMessageContent: (state, action: PayloadAction<string>) => {
      state.messageContent = action.payload;
    },

    setHasApplied: (state, action: PayloadAction<number>) => {
      state.hasApplied = action.payload;
    },

    setSelectedCourse: (state, action: PayloadAction<string[]>) => {
      state.selectedCourse = action.payload;
    },

    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },

    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },

    setPhone: (state, action: PayloadAction<string>) => {
      // console.log('Redux Action - setPhone:', action.payload);
      state.phone = action.payload;
    },

    setWhatsAppNumber: (state, action: PayloadAction<string>) => {
      state.whatsappNumber = action.payload;
    },

    setChannels: (state, action: PayloadAction<channelTypeProps[]>) => {
      state.channels = action.payload;
    },

    setIsWhatsAppNumber: (state, action: PayloadAction<boolean>) => {
      state.isWhatsAppNumber = action.payload;
    },

    setCourseId: (state, action: PayloadAction<string[]>) => {
      state.courseId = action.payload;
    },

    setDialCode: (state, action: PayloadAction<string>) => {
      state.dialCode = action.payload;
    },

    setCountryCode: (state, action: PayloadAction<string>) => {
      state.countryCode = action.payload;
    },

    setCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },

    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setUniversityPreference: (state, action: PayloadAction<Partial<universityPreferrenceDto>>) => {
      state.universityPreferrenceDto = {
        ...state.universityPreferrenceDto,
        ...action.payload,
      };
    },

    resetSayHelloPopupState: () => initialState,
  },
});

export const {
  setMessageContent,
  setSelectedCourse,
  setHasApplied,
  setName,
  setEmail,
  setPhone,
  setChannels,
  setWhatsAppNumber,
  resetSayHelloPopupState,
  setIsWhatsAppNumber,
  setCourseId,
  setDialCode,
  setCountryCode,
  setCity,
  setAddress,
  setUniversityPreference,
} = sayHelloPopupSlice.actions;

export const getSayHelloPopupSelector = (state: RootState) => state.chatPopups;

export default sayHelloPopupSlice.reducer;
