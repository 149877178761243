import { getHost } from '../../../../utils/hostDataFunction';

export type IndianCustomState = {
  id: number;
  stateName: string;
};

export type indianCustomCity = {
  id: number;
  cityName: string;
};

export const getIndianStateRequest = async () => {
  const uniId = getHost();
  return await fetch(`${process.env.REACT_APP_API_URL}/university/${uniId}/custom-states`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  }).then((res) => res.json());
};

export const getIndianCityRequest = async (stateId: number) => {
  const uniId = getHost();
  return await fetch(`${process.env.REACT_APP_API_URL}/university/${uniId}/custom-states/${stateId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  }).then((res) => res.json());
};
